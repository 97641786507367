import {
  ButtonDiv,
  Container,
  ContainerItem,
  ContainerMenu,
  ContainerSaldo,
  ResgateDiv,
  Select,
} from "./styles";
import { Box, Modal, Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { RootState } from "../../../../service/store";
import { useSelector } from "react-redux";
import TableListData from "../../../../components/TableData";
import { CsvBuilder } from "filefy";
import { logs } from "../../../../service/requests/logs";
import { employee } from "../../../../service/requests/employee/calls";
import { ExtratoUsuario } from "./types";

const modalObj = {
  imagem: "",
  valor: 0,
  nome: "",
};

export default function ConsultaFuncionarios() {
  const [open, setOpen] = useState(false);
  const user = useSelector((state: RootState) => state.user.funcionario.id);
  const [collection, setColletion1] = useState([]);
  const [extract, setExtract] = useState<ExtratoUsuario[]>();

  useEffect(() => {
    employee.extratoGestores().then((response) => {
      const { data } = response;
      setExtract(data);   
    });

  }, []);



  return (

      <TableListData
        title="Saldo Gestores"
        data={ extract || []}
        columns={[
          // { title: "Id", field: "id" },
          { title: "Nome", field: "nome" },
          { title: "Saldo Interno", field: "saldoInterno" },
          { title: "Saldo Externo", field: "saldoExterno" },
          
        ]}
        options={{
          actionsColumnIndex: -1,
          exportAllData: true,
          exportCsv: (data, columns) => {
            const columnTitles = data.map((column) => column.title);

            const csvData = columns.map((rowData) =>
              data.map((columnDef) => rowData[columnDef.field])
            );

            const builder = new CsvBuilder(`SaldoGestores.csv`)
              .setDelimeter(";")
              .setColumns(columnTitles)
              .addRows(csvData)
              .exportFile();

            return builder;
          },
        }}
        style={{
          color: "#133777",
        }}
      />
      
      
    
  );
}
