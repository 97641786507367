// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  box-sizing: border-box;
}
body {
  background-color: #fff;
}

*,
*:after,
*:before {
  box-sizing: inherit;
  font-family: Roboto;
}

button {
  margin: 1rem;
  border: 0;
  outline: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
}

p {
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding: 0;
  margin: 0;
  font-family: Roboto, sans-serif;
}

a {
  text-decoration: none;
  font-family: Roboto, sans-serif;
}

span {
  font-family: Roboto, sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/styles/GlobalStyles.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,sBAAsB;AACxB;AACA;EACE,sBAAsB;AACxB;;AAEA;;;EAGE,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,SAAS;EACT,UAAU;AACZ;;AAEA;;;;;;EAME,SAAS;EACT,UAAU;EACV,+BAA+B;AACjC;;AAEA;EACE,sBAAsB;EACtB,oBAAoB;EACpB,UAAU;EACV,SAAS;EACT,+BAA+B;AACjC;;AAEA;EACE,qBAAqB;EACrB,+BAA+B;AACjC;;AAEA;EACE,+BAA+B;AACjC","sourcesContent":["* {\r\n  margin: 0;\r\n  box-sizing: border-box;\r\n}\r\nbody {\r\n  background-color: #fff;\r\n}\r\n\r\n*,\r\n*:after,\r\n*:before {\r\n  box-sizing: inherit;\r\n  font-family: Roboto;\r\n}\r\n\r\nbutton {\r\n  margin: 1rem;\r\n  border: 0;\r\n  outline: 0;\r\n}\r\n\r\nh1,\r\nh2,\r\nh3,\r\nh4,\r\nh5,\r\nh6 {\r\n  margin: 0;\r\n  padding: 0;\r\n  font-family: Roboto, sans-serif;\r\n}\r\n\r\np {\r\n  margin-inline-start: 0;\r\n  margin-inline-end: 0;\r\n  padding: 0;\r\n  margin: 0;\r\n  font-family: Roboto, sans-serif;\r\n}\r\n\r\na {\r\n  text-decoration: none;\r\n  font-family: Roboto, sans-serif;\r\n}\r\n\r\nspan {\r\n  font-family: Roboto, sans-serif;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
