import { FormControlLabel, RadioGroup, Radio, FormControl } from "@mui/material";
import { useState } from "react";
import { Container } from "./styles";
import { useSelector } from "react-redux";
import { RootState } from "../../service/store";
import ConsultaFuncionarios from "./components/ConsultaFuncionarios";
import ConsultaGestores from "./components/ConsultaGestores";

export const Saldo = () => {
  const [formValue, setFormValue] = useState<String>("gestores");
  const permission = useSelector(
    (state: RootState) => state.user.tipoUsuario?.id
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValue((event.target as HTMLInputElement).value);
  };

  return (
    <Container>
      {permission === 1 ? (
        <FormControl>
          <RadioGroup
            row
            name="use-radio-group"
            onChange={handleChange}
            value={formValue}
          >
          <FormControlLabel
            value="gestores"
            label="Gestores"
            control={<Radio />}
            labelPlacement="bottom"
          />
            <FormControlLabel
              value="funcionarios"
              label="Funcionários"
              control={<Radio />}
              labelPlacement="bottom"
            />
          </RadioGroup>
        </FormControl>
      ) : null}
      <Container>
        {formValue === "funcionarios" && <ConsultaFuncionarios />}
        {formValue === "gestores" && <ConsultaGestores />}
      </Container>
     
    </Container>
  );
};
function setFormValue(value: string) {
  throw new Error("Function not implemented.");
}

