import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  font-family: "Roboto";
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 10px;
  width: 100%;
  gap: 30px;
  @media (max-width: 768px) {
    height: 180vh;
    width: 40vh;
  }
`;

export const DatePicker = styled.div`
  width: 100%;
  display: flex;
  column-gap: 20px;
  justify-content: center;
  margin-bottom: 20px;
  align-items: flex-end;
`;

export const InputDate = styled.input`
  width: 100%;
  height: 32px;
  cursor: pointer;
  padding-left: 5px;
  border: 1px solid rgba(0, 37, 133, 0.3);
  background: #ffffff;
  flex-direction: row-reverse;
  color: rgba(0, 37, 133, 0.3);
  border-radius: 5px;
`;

export const InputDiv = styled.div`
  display: flex;
  width: 200px;
  flex-direction: column;
  margin-left: 5px;
  align-items: flex-start;
`;

export const LabelDate = styled.label`
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  margin-bottom: 4px;
`;

export const SearchButton = styled.button`
  border-radius: 5px;
  width: 150px;
  height: 32px;
  font-size: 15px;
  padding: 0px;
  border: 1px solid rgba(0, 37, 133, 0.3);
  color: rgba(0, 37, 133, 0.3);
  background-color: #fff;
  margin: 0;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  column-gap: 8px;
  display: flex;
  transition: 0.5s;
  :hover {
    color: #133777;
    background-color: #fff;
  }
`;
