import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { FormContainer, MainContainer } from "./styles";
import TextField from "@mui/material/TextField";
import Button from "../../components/Button";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch } from "react-redux";
import { fetchDataLogin } from "../../service/store/features/userSlices";
import { getMail, getPassword } from "./utils";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../service/store";

export default function Login() {
	const [loginEmail, setLoginEmail] = useState<string>(getMail);
	const [senha, setSenha] = useState<string>(getPassword);
	const [salvar, setSalvar] = useState<boolean>(false);
	const dispatch = useDispatch();
	const error = useSelector((state: RootState) => state.user.error);
	const [inputType, setInputType] = useState("password");

	function showPassword() {
		setInputType("text");
	}

	function hidePassword() {
		setInputType("password");
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		dispatch(
			fetchDataLogin({
				email: loginEmail,
				senha: senha,
				funcionario: { id: 1 },
			})
		);
	};

  useEffect(() => {
    const verifyLocalSave = (save: boolean) => {
      if (save) {
        localStorage.setItem("@InfoUser:mail", `${loginEmail}`);
        localStorage.setItem("@InfoUser:password", `${senha}`);
      }
    };
    verifyLocalSave(salvar);
  }, [salvar, loginEmail, senha]);
  return (
    <>
      <MainContainer>
        <FormContainer>
          <div>
            <img src={require("../../images/logo.png")} alt={"logo"} />
          </div>
          <Typography variant="h1" fontWeight={700} fontSize={30}>
            Méritos Schmersal
          </Typography>
          <Typography variant="h2" fontWeight={700} fontSize={36}>
            We are turning workplaces into safer places.
          </Typography>
          <Typography
            variant="h3"
            fontWeight={700}
            fontSize={18}
            color={"#00000099"}
          >
            Bem vindo de volta! Por favor faça login na sua conta
          </Typography>
          <form className={"login-container"}>
            <div className="input-forms">
              <div className="input-forms">
                <TextField
                  className="input-field"
                  label="Email"
                  onChange={(e) => setLoginEmail(e.target.value)}
                  value={loginEmail}
                />
                <TextField
                  className="input-field"
                  label="Senha"
                  type={inputType}
                  id="inputPassword"
                  onChange={(e) => setSenha(e.target.value)}
                  value={senha}
                ></TextField>
              </div>
              {error ? (
                <div
                  style={{
                    color: "#e74c3c",
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Roboto",
                  }}
                >
                  Email ou senha invalido!
                </div>
              ) : null}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "400px",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    color: "#00000099",
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Roboto",
                  }}
                >
                  <Checkbox
                    value={salvar}
                    onChange={(e) => setSalvar(e.target.checked)}
                  />
                  Lembrar
                </div>
                <div>
                  <Link
                    to="/recuperar"
                    style={{ textDecoration: "none", color: "#00000099" }}
                  >
                    Esqueceu a senha?
                  </Link>
                </div>
                <div>
                  <Typography
                    style={{
                      textDecoration: "none",
                      color: "rgba(0, 0, 0, 0.6)",
                      fontFamily: "Roboto, sans-serif",
                      margin: "3px 0 0 0",
                    }}
                    onMouseOver={showPassword}
                    onMouseOut={hidePassword}
                  >
                    Mostrar senha
                  </Typography>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                color: "#00000099",
                justifyContent: "center",
                gap: "33px",
              }}
              className="form-button"
            >
              <Button onClick={handleSubmit} variant="outlined">
                Login
              </Button>
            </div>
            <div
              style={{
                paddingTop: "50px",
                fontFamily: "roboto",
                color: "#00000099",
              }}
            >
              v1.2.0
            </div>
          </form>
        </FormContainer>
      </MainContainer>
    </>
  );
}
